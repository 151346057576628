main.loginpage{
    width: 21em;
    height: 24em;
    margin: 3em auto;
    background-color: none;
    border: 1px;
    border-style: solid;
    border-radius: 1em;
    border-color: rgb(239, 233, 233);
}

main.registerpage{
    width: 21em;
    height: 28em;
    margin: 3em auto;
    background-color: none;
    border: 1px;
    border-style: solid;
    border-radius: 1em;
    border-color: rgb(239, 233, 233);
}

main.errpage{
    width: 21em;
    height: 13em;
    margin: 3em auto;
    background-color: none;
    border: 1px;
    border-style: solid;
    border-radius: 1em;
    border-color: rgb(239, 233, 233);
}
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.input-label {
    display: block;
    margin-bottom: 5px;
    text-align: center;
}

.input-field {
    width: 65%;
    padding: 5px;
    border: 2px solid #61DAFB;
    background-color: #cdcaca;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; 
}

.submit-button {
    padding: 10px 20px;
    background-color: #aaa;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #61DAFB; 
}

.center-text {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}




.registerpage{
    width: 21em;
    height: 12em;
    margin: 3em auto;
    background-color: none;
    border: 1px;
    border-style: solid;
    border-radius: 1em;
    border-color: rgb(239, 233, 233);
}


h4.joinus {
    text-align: center;
    margin-top: 20px;
  }

.redirectRegister{
    color: #61DAFB;
}